import React from 'react';

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={65} height={65} viewBox="0 0 115 117" {...props}>
      <path
        d="M114.957 58.504c0-32.309-25.723-58.5-57.46-58.5C25.761.004.034 26.195.034 58.504s25.727 58.5 57.461 58.5c31.738 0 57.461-26.192 57.461-58.5zm0 0"
        fillRule="evenodd"
        fill="#6441a5"
      />
      <path
        d="M15.867 30.621v49.547h8.95V45.715l11.245 24.734h7.84L55.23 45.793v15.582c.57.145.43.406.008.723.282.215.598.465.977.797.027.675.008 1.296-.07 1.968-.008.078.023-.152.035-.226-.02.101.011.21-.035.304-.012.028-.09-.011-.083.016.149.57.551 1.121.399 1.754-.055-.023-.184-.133-.168-.074.039.156.187.261.25.406.316.734.434 1.2.375 1.953l-.082.567c.066.144.14.289.207.437.047.375.113.746.14 1.125.005.082-.035.164-.05.246-.028.035-.082.059-.082.102.004.117.062.226.094.34.421 1.472.039.132.48 1.687.168.527.258 1.086.523 1.57.547 1.012.664 1.07 1.524 1.922 1.297 1.293.906.95 2.058 1.817.083.125.114.296.243.37.086.055.218.063.351.071l.082-.082c.215.094.45.152.653.277.28.176.57.426.804.723h.317V30.621H52.852L40.184 58.832 27.59 30.622zm39.371 31.477l-.008-.008v.015c0-.003.004-.007.008-.007zm1.477 7.175c-.004.004-.004.008 0 .012.035.094.078.184.12.274 0-.055 0-.106-.007-.137-.012-.05-.105-.156-.113-.149zm.117.32c-.008.06-.02.122-.027.18-.008.079.015-.05.027-.18zm5.855 9.708c.051.027.083.066.075.133-.016.136-.035.273-.055.41.04.023.074.05.117.074l.145-.047c-.047.016-.094.074-.137.05-.004-.003-.008-.003-.008-.007a1.93 1.93 0 01-.14.043c-.012.07-.024.14-.04.211h.836c-.261-.496-.566-.86-.792-.867zm-7.457.687v.18h.122l-.122-.18zm0 0"
        fill="#fff"
      />
      <path
        d="M90.434 48.145c-3.59 0-6.364.734-8.317 2.21-1.898 1.477-3.273 4.032-4.117 7.665l-2.613 10.984c-.371 1.582-.922 2.61-1.664 3.082-.684.527-1.82.793-3.407.793-2.53 0-4.25-.633-5.144-1.899-.848-1.21-1.27-3.37-1.27-6.48 0-1.992.094-4 .278-6.02a3.529 3.529 0 01-1.11-.308c-.129-.063-.117-.606-.086-1.086a4.413 4.413 0 01-.757-.695c-.618-.7-.61-2.38-.551-3.164.07-.93.25-1.852.375-2.774.222-.633.433-1.273.672-1.902.043-.11.086-.219.125-.328h-5.676c-.633 1.683-1.16 4.004-1.582 6.953a66.366 66.366 0 00-.637 9.324c0 6.219 1.215 10.8 3.645 13.75 2.43 2.95 6.441 4.426 12.039 4.426 2.164 0 3.984-.211 5.46-.633 1.481-.367 2.723-.95 3.723-1.738.899-.79 1.637-1.79 2.22-3.004.632-1.211 1.241-3.055 1.82-5.531l2.535-10.668c.316-1.317.82-2.266 1.503-2.844.743-.528 1.797-.79 3.168-.79 2.38 0 4.04.657 4.993 1.973 1 1.32 1.504 3.559 1.504 6.72 0 1.894-.106 3.741-.317 5.53-.16 1.793-.531 4.188-1.11 7.192h8.552c.476-1.738.82-3.637 1.03-5.692.266-2 .395-4.293.395-6.875 0-6.214-1.265-10.796-3.8-13.75-2.536-2.949-6.493-4.421-11.88-4.421zm-27.34 6.03c-.274.184-.375.497-.54.888-.464 1.109-.105.984.52.523.02-.469.02-.941.02-1.41zm.164 3.075c.004.14.008.164.023.012l-.023-.012zm0 0"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
